import { compare, CompareOptions } from "natural-orderby";
import { SortDirections } from "./";

interface LocaleCompareBlankBottom {
  key: string;
  direction: SortDirections;
}

export default ({ key, direction }: LocaleCompareBlankBottom) => {
  return (objA: { [key: string]: string }, objB: { [key: string]: string }) => {
    const a = objA[key];
    const b = objB[key];

    if (!a) return 1;
    if (!b) return -1;

    return compare({
      order: direction === SortDirections.Desc ? "desc" : "asc",
    })(a, b);
  };
};
