import React, { useState, useMemo, ReactNode } from "react";
import { graphql } from "gatsby"; // to query for image data
import SongIndexPage from "../components/SongIndexPage";
import {
  FilteredSongSchema,
  SongProps,
  SongSchema,
  SongIndexFiltersEnum,
  SortDirections,
} from "../lib/";
import { PerformanceSchema } from "../lib/";
import blankBottomSort from "../lib/blankBottomSort";

export const query = graphql`
  query {
    allSongs {
      nodes {
        filename
        slug
        authors
        title
        publish_date(formatString: "Y-MM-DD")
        recordings {
          id
        }
      }
    }

    allPerformances {
      nodes {
        date(formatString: "Y-MM-DD")
        filename
        setlist
      }
    }
  }
`;

const transformSong = (
  song: SongSchema,
  performanceObject: PerformanceSchema[]
): SongProps => {
  const filteredPerformances = performanceObject.filter((performance) =>
    performance.setlist.includes(song.filename)
  );
  return {
    key: song.filename,
    song: song.title,
    authors: song.authors.join(", "),
    publish_date: song.publish_date,
    times_played: filteredPerformances.length,
    first_performed: filteredPerformances[0] && filteredPerformances[0].date,
    last_performed:
      filteredPerformances[filteredPerformances.length - 1] &&
      filteredPerformances[filteredPerformances.length - 1].date,
    slug: song.slug,
    recordings_count: (song.recordings && song.recordings.length) || 0,
  };
};

const filterData = (song, selectedFilter: SongIndexFiltersEnum) => {
  if (selectedFilter === SongIndexFiltersEnum.Originals) {
    return song.authors.includes("Rich Soni");
  }
  if (selectedFilter === SongIndexFiltersEnum.Covers) {
    return !song.authors.includes("Rich Soni");
  }
  return true;
};

export const useFilteredSongs = (nodes: SongSchema[]): FilteredSongSchema => {
  const [selectedFilter, setSelectedFilter] = useState(
    SongIndexFiltersEnum.All
  );
  return useMemo(
    (): FilteredSongSchema => ({
      songs: nodes.filter((song) => filterData(song, selectedFilter)),
      selectedFilter,
      setSelectedFilter,
    }),
    [nodes, selectedFilter, setSelectedFilter]
  );
};

export const usePerformances = (nodes: PerformanceSchema[]) => {
  return useMemo((): PerformanceSchema[] => {
    return nodes
      .filter((performance) =>
        performance.setlist ? performance.setlist.length > 0 : false
      )
      .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
  }, [nodes]);
};

export default ({ data, location }) => {
  const [selectedSortKey, setSelectedSortKey] = useState("song");
  const [selectedSortDirection, setSelectedSortDirection] = useState(
    SortDirections.Asc
  );
  const allPerformances = usePerformances(data.allPerformances.nodes);
  const { selectedFilter, setSelectedFilter, songs } = useFilteredSongs(
    data.allSongs.nodes
  );
  console.log(selectedSortDirection);
  const songProps = songs
    .map((s) => transformSong(s, allPerformances))
    .sort(
      blankBottomSort({
        key: selectedSortKey,
        direction: selectedSortDirection,
      })
    );

  return (
    <SongIndexPage
      location={location}
      setSelectedFilter={setSelectedFilter}
      selectedFilter={selectedFilter}
      selectedSortDirection={selectedSortDirection}
      setSelectedSortDirection={setSelectedSortDirection}
      selectedSortKey={selectedSortKey}
      setSelectedSortKey={setSelectedSortKey}
      songs={songProps}
    />
  );
};
