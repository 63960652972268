import React from "react"
import {
  SongIndexPageProps,
  SongIndexFiltersEnum,
  SONG_INDEX_COLUMNS,
} from "@richsoni/gatsby-theme-core/src/lib"
import Layout from "../../../components/headerFooterLayout"
import { Radio, Table } from "antd"
import markdownTableNav from "../../../lib/markdownTableNav"
import blankBottomSort from "@richsoni/gatsby-theme-core/src/lib/blankBottomSort"

const cols = SONG_INDEX_COLUMNS.map(({ key, title }) => {
  return {
    key,
    title,
    dataIndex: key,
    sorter: (a, b, dir) => blankBottomSort({ key, direction: dir })(a, b),
    sortDirections: ["ascend", "descend"],
  }
})

export default ({
  songs,
  location,
  setSelectedFilter,
  selectedFilter,
}: SongIndexPageProps) => {
  return (
    <Layout currentPath={location.pathname}>
      <Radio.Group
        defaultValue="ALL"
        onChange={(e) => setSelectedFilter(e.target.value)}
        value={selectedFilter}
        buttonStyle="solid"
      >
        {Object.keys(SongIndexFiltersEnum).map((f) => (
          <Radio.Button key={f} value={f}>
            {SongIndexFiltersEnum[f]}
          </Radio.Button>
        ))}
      </Radio.Group>
      <div style={{ height: "20px", width: "100%" }} />
      <Table
        style={{ cursor: "pointer" }}
        bordered={false}
        pagination={false}
        size="small"
        dataSource={songs}
        columns={cols}
        onRow={markdownTableNav}
      />
    </Layout>
  )
}
